import { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, useLocation  } from "react-router-dom";
import MainPage from '@pages/main';
import { Toaster, toast } from 'sonner';
import Order from '@pages/order';

function App() {
  return (
  <Router basename="/">
    <Toaster position="bottom-right" richColors expand={false} />
    <Routes>
      <Route path='*' element={<MainPage />} />
      <Route path='/order/:id' element={<Order/>} />
      <Route path='/' element={<MainPage />} />
    </Routes>
  </Router>
  )
}

export default App
