import React, { useState, useEffect } from "react";
import MainContent from "@components/content";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import css from './index.module.css';
import { useTranslation } from 'react-i18next';
import i18n from '@locales/index';

export default function Order() {
  const [available, setAvaiable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  return (
    <MainContent> 
      <div className={css.hello}>
        <h1>Ого! Вы попали на страницу заказа!</h1>
        <a>Сейчас я дорабатываю функционал личного кабинета, скоро он будет доступен.</a>

        <Link className={css.back} to="/">Вернуться на главную</Link>
      </div>

      <div className={css.qr}>
        <b>Зачем QR код в договоре, если страница недоступна?</b>
        <a>Я делаю это для Вашего удобства, чтобы после запуска личного кабинета Вы могли быстро перейти к нужному заказу из договора.</a>
      </div>
    </MainContent>
  )
}